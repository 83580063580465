import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import SEOComponent from "../../components/seo"
import { Hero } from "../../components/hero"
import PageCTA from "../../components/pageCTA"
import StaffGroup from "../../components/staffGroup"
import StaffCard from "../../components/staffCard"

export const query = graphql`
  query StaffPageQuery {
    file(name: { eq: "staff-banner-handshake" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allContentfulStaffDepartments {
      nodes {
        title
        department {
          references {
            departmentName
            departmentBlock {
              references {
                name
                suffix
                position
                email
                permalink
                profileImage {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                  )
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
`

const StaffPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <SEOComponent title="Our Staff" description="The staff of Strong Foundations CT" />
      <Hero image={banner} header="Our Staff" />

      <section className="mt-4 md:mt-8 mb-14">
        <div className="container mx-auto px-4 md:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-12 gap-4 md:gap-8">
            <div className="font-serif space-y-2 xl:col-span-7">
              <h2 className="text-3xl">Staff Philosophy</h2>
              <p className="text-base sm:text-lg md:text-xl text-gray-500 leading-relaxed">
                The Behavior Analysts and direct care staff at Strong
                Foundations support the development of all children. They share
                in their belief that ALL children provided with strong
                foundations, such as mastery and fluency in prerequisite skills,
                will be able to keep moving forward in becoming participating
                and functional members of their communities. At Strong
                Foundations, we approach each child as an individual with their
                strengths and one-of-a-kind needs.
              </p>
            </div>
            <div className="xl:col-span-5 self-center">
              <StaticImage
                className="rounded shadow-md"
                src="../../images/staff/staff-pic.jpg"
                alt="The staff of Strong Foundations CT"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container my-4 mx-auto pt-0 px-4 pb-6">
        {data.allContentfulStaffDepartments.nodes[0].department.references.map((department, i) => (
          <StaffGroup key={i} name={department.departmentName}>
            {department.departmentBlock.references.map((member, i, array) => (
              <StaffCard key={i} member={member}/>
            ))}
          </StaffGroup>
        ))}
      </section>
      <PageCTA
        h2="Ready to find out more?"
        buttonText="Learn more"
        destination="/contact-us/"
      >
        <p className="font-serif text-gray-500 text-xl" >
          Review the{" "}
          <Link to="/services" title="Services">
            services
          </Link>{" "}
          we offer, ask any questions{" "}
          <Link to="/contact-us/" title="Contact us">
            here
          </Link>
          , or call us today!
        </p>
      </PageCTA>
    </Layout>
  )
}

export default StaffPage
