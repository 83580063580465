import * as React from "react"
import PropTypes from "prop-types"

/**
 * Primary UI component for user interaction
 */

const Button = ({ primary, label, ...props }) => {
  const mode = (
    primary
      ? "bg-purple-500 hover:bg-white text-white hover:text-purple-500"
      : "bg-transparent hover:bg-purple-500 text-purple-500 hover:text-white"
  )

  return (
    <button
      type="button"
      className={`border border-purple-500 px-6 py-4 rounded-full font-sans uppercase ${mode}`}
      {...props}
    >
      {label}
    </button>
  )
}

export default Button

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
   primary: PropTypes.bool,
   /**
   * Button contents
   */
  label: PropTypes.string.isRequired
}
