import * as React from "react"

const StaffGroup = ({ children, name }) => (
  <>
    <div className="text-center">
      <hr className="m-4" />
      <h2 className="font-serif text-3xl">{name}</h2>
      <hr className="m-4" />
    </div>
    <div className="flex flex-wrap justify-center">
      {children}
    </div>
  </>
)

export default StaffGroup