import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const StaffCard = ({ member }) => (
  <div className="py-4 px-3 md:p-4 w-1/2 sm:w-1/3 md:w-1/4 text-center">
    <GatsbyImage
      className="mb-2"
      image={member.profileImage.gatsbyImageData}
      alt={`${member.name} profile picture`}
    />
    <Link to={`/staff${member.permalink}`} title={member.name}>
      <h3 className="text-lg md:text-xl lg:text-2xl">{member.name}</h3>
    </Link>
    <p className="text-sm md:text-base lg:text-lg">{member.position}</p>
  </div>
)

export default StaffCard